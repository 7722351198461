const menu_data = [
  {
    id: 1,
    mega_menu: false,
    has_dropdown: false,
    title: "Home",
    link: "/",
    active: "active",
  },
  {
    id: 2,
    mega_menu: false,
    has_dropdown: false,
    title: "Quiz Play",
    link: "/quiz-play",
    active: "",
  },
  // {
  //   id: 3,
  //   mega_menu: false,
  //   has_dropdown: false,
  //   title: "bookmark",
  //   link: "/profile/bookmark",
  //   active: "",
  // },
  // {
  //   id: 4,
  //   mega_menu: false,
  //   has_dropdown: false,
  //   title: "Invite Friends",
  //   link: "/profile/invite-friends",
  //   active: "",
  // },
  // {
  //   id: 5,
  //   mega_menu: false,
  //   has_dropdown: false,
  //   title: "Instruction",
  //   link: "/instruction",
  //   active: "",
  // },
  // {
  //   id: 6,
  //   mega_menu: false,
  //   has_dropdown: false,
  //   title: "LeaderBoard",
  //   link: "/leaderboard",
  //   active: "",
  // },
  {
    id: 7,
    mega_menu: false,
    has_dropdown: true,
    title: "More",
    link: "/",
    active: "",
    sub_menus: [
      { link: "/contact-us", title: "Contact Us" },
      { link: "/about-us", title: "About Us" },
      { link: "/terms-conditions", title: "Terms and Conditions" },
      { link: "/privacy-policy", title: "Privacy Policy" },
    ],
  },
];
export default menu_data;
